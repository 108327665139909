import * as FontAwesome from "react-fontawesome";
import * as MXTS from "@maxxton/cms-mxts-api";
import * as React from "react";
import * as classNames from "classnames";

import { Button, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { WebContent as CmsApiWebContent, Locale, LocaleApi, Site, Template, TemplateApi, Widget, WithId } from "@maxxton/cms-api";
import { CustomerLoginResult, getCustomerDetailsByLogin, loginCustomer } from "../../../utils/authToken.util";
import { FormSpec, InputSpec } from "../../../form-specs";
import { GuestFormAction, GuestFormActionType } from "../../../redux/actions/guestFormAction";
import { IWithGoogleReCaptchaProps, withGoogleReCaptcha } from "react-google-recaptcha-v3";
import { LoginSuccessParams, UpdatePasswordUrlParams } from "../userLogin/userLoginWidget";
import { RuleNames, dynamicFormUtil } from "./dynamicForm.util";
import { getDownloadableUrl, getHideWidgetClass, getNoDataFoundContent, getNoDataFoundTemplate, isClientLoggedIn } from "../../../components/utils";
import { getI18nLocaleString, wrapProps } from "../../../i18n";
import { getLocalizedContent, transformLocaleIntoCurrentLocale } from "../../../utils/localizedContent.util";
import { parseCustomer, validateCustomerData } from "../../../utils/customer.util";

import { ActionType } from "../../../redux/actions";
import { Alerts } from "../../../alerts";
import { AvailabilityState } from "../../../redux/reducers/availability.types";
import { BillState } from "../../../redux/reducers/billReducer";
import { CMSProviderProperties } from "../../../containers/cmsProvider.types";
import { CreateReservationAction } from "../../../redux/actions/reservationAction";
import { CurrentLocale } from "../../../app.types";
import { Dispatch } from "redux";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { FlexboxBase } from "../flexbox/Flexbox";
import { FlowWidgetTypes } from "../../flow/flow.util";
import { GuestFormState } from "../../../redux/reducers/guestFormReducer";
import { Identity } from "../../../containers/Identity";
import { LOCAL_STORAGE_KEYS } from "../../../utils/constants";
import { MyEnvReducerAction } from "../../../redux/actions/myEnvAction";
import { MyEnvState } from "../../../redux/reducers/myEnv/myEnvState";
import { PageWidgetBaseProps } from "../pageWidget.types";
import { RESTRICTION_RULE_OPTIONS } from "../../../utils/bookRestriction.util";
import { ReservationState } from "../../../redux/reducers/reservationReducer";
import { ResultsPanelAction } from "../../../redux/actions/resultsPanelAction";
import { RevealerContext } from "../../shared/revealer/Revealer";
import { SmartLink } from "../../../components/SmartLink";
import { State } from "../../../redux";
import { TemplateWidget } from "../template/Template";
import { UrlParamsUtil } from "../../../utils/urlparam.util";
import { UserLoginFormActionType } from "../../../redux/actions/userLoginFormAction";
import { UserLoginFormState } from "../../../redux/reducers/userLoginReducer";
import { WidgetOptions as UserLoginWidgetOptions } from "../userLogin/index";
import { WidgetOptions } from "../guestInterfaceWidget";
import { connect } from "react-redux";
import { dynamicFilterType } from "../../../redux/reducers/dynamicFilter.enum";
import { getCustomerMailAddress } from "../../../utils/address.util";
import { getFallbackLocaleIds } from "../button/button.util";
import { getLoginPageRedirectUrl } from "../../../routing/routing.util";
import { getMainCustomerIdFromLoginToken } from "../../../redux/reducers/myEnv/myEnv.util";
import { getMxtsEnv } from "../../mxts";
import { getMyEnvMainCustomer } from "../../../redux/reducers/myEnv/myEnvState.util";
import { getResortIdFromFilters } from "../../../utils/resort.util";
import { globalApiContext } from "../../../containers/CmsProvider";
import { isClientSide } from "../../../utils/generic.util";
import loadable from "@loadable/component";
import { loadableRetry } from "../../../utils/loadableComponents.util";
import namespaceList from "../../../i18n/namespaceList";
import { pageLink } from "../../../routing";
import { parse } from "query-string";
import { redirectToPostReservationCreationPage } from "./reservationCreation.util";
import { removeSelfNestedTemplates } from "../template";
import { renderNoResultsFoundContent } from "../../dynamic/containerWidget.util";
import { renderPageWidgets } from "../../widget";

const GenericInput = loadable(() => loadableRetry(() => import("../../../components/generic-form/input")), {
    resolveComponent: ({ GenericInput }) => GenericInput,
});
const Revealer = loadable(() => loadableRetry(() => import("../../shared/revealer/Revealer")), {
    resolveComponent: ({ Revealer }) => Revealer,
});
const WebContent = loadable(() => loadableRetry(() => import("../web-content/WebContent")), {
    resolveComponent: ({ WebContent }) => WebContent,
});

export enum LoginStatus {
    INITIAL = "INITIAL",
    LOADING = "LOADING",
    SUCCESS = "SUCCESS",
    FAILED = "FAILED",
    FAILED_TOO_MANY_RETRIES = "FAILED_TOO_MANY_RETRIES",
}

export enum ForgotPasswordStatus {
    INITIAL = "INITIAL",
    CUSTOMER_NOT_FOUND = "CUSTOMER_NOT_FOUND",
    SUCCESS = "SUCCESS",
    FAILED = "FAILED",
}

export enum UpdatePasswordStatus {
    INITIAL = "INITIAL",
    SUCCESS = "SUCCESS",
    FAILED = "FAILED",
    FAILED_PW_SAME_AS_OLD = "FAILED_PW_SAME_AS_OLD",
}

export interface FormSubmitOptions {
    onSubmit: (submitDetails: FormSubmitDetails) => void;
    includeRecaptchaToken?: boolean;
}

export interface FormSubmitDetails {
    reCaptchaToken?: string;
    getRecaptchaToken: () => Promise<string>;
    draftValue: any;
}

interface DynamicFormBaseProps<E> extends PageWidgetBaseProps<WidgetOptions> {
    className: string;
    spec: FormSpec<E>;
    value: E & { _id: string };
    rootValue?: any;
    alerts: Alerts;
    context: CMSProviderProperties;
    mandatoryFields: string[];
    userLoginInterface?: boolean;
    onLoginSuccess?: (params: LoginSuccessParams) => Promise<void>;
    permission: MXTS.PermissionType;
    googleReCaptchaProps?: IWithGoogleReCaptchaProps;
    isUserLoginWidget?: boolean;
    isGuestInterfaceWidget?: boolean;
    submitOptions?: FormSubmitOptions;
    currentFlow?: Widget;
}

interface DynamicFormStoreProps {
    dynamicFilter: DynamicFilter;
    reservationState: ReservationState;
    billState: BillState;
    guestFormState: GuestFormState;
    myEnvState: MyEnvState;
    availabilityState: AvailabilityState;
    userLoginFormState: UserLoginFormState;
}

interface DynamicFormDispatchProps {
    dispatchAction: Dispatch<CreateReservationAction | GuestFormAction | ResultsPanelAction | MyEnvReducerAction>;
}

export interface DynamicFormProps<E> extends DynamicFormBaseProps<E>, DynamicFormStoreProps, DynamicFormDispatchProps {}

interface DynamicFormState {
    reservationResults: any;
    sources?: any;
    selectedSourceId: string;
    openModal: boolean;
    isLoading: boolean;
    showExistingReservationFeedback?: boolean;
    validations?: {
        [id: string]: {
            isValid: boolean;
            message?: string;
        };
    };
    shouldProceed?: boolean;
    isRedirected: boolean;
    customerData?: Partial<MXTS.Customer>;
    loginStatus?: string;
    restrictedOption?: string;
    forgotPasswordStatus: string;
    isResetTokenValid: boolean;
    updatePasswordStatus: string;
    url?: string;
    ruleDefinitions?: {
        [key in RuleNames]: { valid: boolean; message: string };
    };
    termsHtmlString: string;
    isTermsAndConditionsModalOpen: boolean;
    loginSuccessContent?: (Template & WithId) | null;
    templateChildren?: JSX.Element[];
    failedLoginCount: number;
    fallbackTemplate: JSX.Element[] | null;
    fallbackWebContent: (CmsApiWebContent & WithId) | null;
    redirectUrl?: string;
}

class DynamicFormBase<E> extends React.Component<DynamicFormProps<E>, DynamicFormState> {
    constructor(props: DynamicFormProps<E>) {
        super(props);
        const { reservationState, context, currentFlow } = props;
        this.state = {
            isRedirected: false,
            reservationResults: {},
            selectedSourceId: "",
            openModal: false,
            isLoading: false,
            showExistingReservationFeedback: reservationState.reservation && reservationState.reservation.reservationId && !!currentFlow,
            restrictedOption: "",
            isResetTokenValid: false,
            forgotPasswordStatus: "",
            updatePasswordStatus: "",
            termsHtmlString: "",
            isTermsAndConditionsModalOpen: false,
            failedLoginCount: 0,
            fallbackTemplate: null,
            fallbackWebContent: null,
        };
    }

    public componentDidMount() {
        const { context, dispatchAction, dynamicFilter, userLoginFormState, options, userLoginInterface, spec } = this.props;
        if (options.showSources) {
            this.getsources();
        }
        if (options.sessionExpiredFallback && (options.sessionExpiredFallbackWebContentId || options.sessionExpiredFallbackTemplateId)) {
            this.getSessionExpiredFallback();
        }
        if (dynamicFilter?.utmSource?.toLowerCase() === "tt") {
            this.setState({ selectedSourceId: "2032002" });
        }
        if (userLoginInterface) {
            this.setState({ loginStatus: LoginStatus.INITIAL });
        }
        if (options.forgotPasswordInterface) {
            this.setState({ forgotPasswordStatus: ForgotPasswordStatus.INITIAL, validations: userLoginFormState.validations?.[spec.id] || {} });
        }
        if (options.updatePasswordInterface && !options.updatePasswordAfterUserLoggedIn) {
            this.checkResetTokenValid().then((isResetTokenValid) => {
                this.setState({ isResetTokenValid, updatePasswordStatus: UpdatePasswordStatus.INITIAL });
            });
        }
        const { currentLocale, site } = context;
        const localContent = getLocalizedContent({ site, currentLocale, localizedContent: options.localized });
        if (localContent?.reservationCategoryId) {
            const action = {
                type: ActionType.DynamicFilter,
                filter: dynamicFilterType.payingCustomerReservationCategoryId,
                payload: { payingCustomerReservationCategoryId: localContent.reservationCategoryId },
            };
            dispatchAction(action);
        }
        if (options?.useMinimalMandatoryFieldsOnly && options?.minimalMandatoryFields?.length) {
            const minimalMandatoryFields = options?.minimalMandatoryFields.map((field) => field.value);
            const action = {
                type: ActionType.GuestForm,
                actionType: GuestFormActionType.updateMinimalMandatoryFields,
                payload: { minimalMandatoryFields },
            };
            dispatchAction(action);
        }
        if (!options.disableAutoFillCustomerDetails) {
            this.handleAutoFillCustomerDetails();
        }
        if (localStorage.getItem(LOCAL_STORAGE_KEYS.MY_ENV_TOKEN)) {
            const myEnvToken = localStorage.getItem(LOCAL_STORAGE_KEYS.MY_ENV_TOKEN);
            const tokenAuthCheck = async () => {
                const env = await getMxtsEnv(context);
                const isMyEnvTokenTokenValid = !!(await context.mxtsApi.isMyEnvAuthTokenValid(env, { token: myEnvToken ? myEnvToken : "" }).catch(() => null));
                let preview;
                if (isClientSide()) {
                    preview = document.querySelector(".backend");
                    preview = !!preview;
                }
                if (isMyEnvTokenTokenValid && userLoginInterface && !options.isBookingFlowLoginRedirect && !preview) {
                    this.redirectAlreadyLoggedInUser(myEnvToken, env);
                }
                if (isMyEnvTokenTokenValid && userLoginInterface && options.fillCustomerDetails) {
                    this.setState({ loginStatus: LoginStatus.SUCCESS });
                }
            };
            tokenAuthCheck();
        }
        const loadTemplate = async () => {
            if (options.fillCustomerDetails && options.loginSuccessTemplate) {
                const template = await TemplateApi.findById({ id: options.loginSuccessTemplate });
                if (template) {
                    const filteredRootTemplates = removeSelfNestedTemplates(template._id, template.root);
                    const templateChildren = await renderPageWidgets(filteredRootTemplates, this.props.context);
                    this.setState({ loginSuccessContent: template, templateChildren });
                }
            }
        };
        loadTemplate();
    }

    public UNSAFE_componentWillReceiveProps(nextProps: DynamicFormProps<E>) {
        const { dynamicFilter, reservationState, guestFormState, userLoginFormState, spec } = nextProps;
        const { isUserLoginWidget, isGuestInterfaceWidget } = this.props;
        const draftValue = isUserLoginWidget ? userLoginFormState.draftValue?.[spec.id] || {} : guestFormState.draftValue;
        const shouldProceed = isGuestInterfaceWidget ? Object.keys(draftValue).length > 0 : this.state.shouldProceed;
        const { reservation, reservedResource, error } = reservationState;
        if (
            Object.keys(reservationState).length > 0 &&
            !error &&
            shouldProceed &&
            dynamicFilter.reservationId &&
            reservation &&
            Object.keys(reservation).length > 0 &&
            reservedResource &&
            Object.keys(reservation.length > 0)
        ) {
            this.setRedirectUrl();
        }
    }

    private async checkResetTokenValid() {
        const { context } = this.props;
        const resetToken = getResetToken();
        if (resetToken) {
            const env = await getMxtsEnv(context);
            const isResetTokenValid = !!(await context.mxtsApi.isMyEnvAuthTokenValid(env, { token: resetToken }).catch(() => null));
            if (isResetTokenValid) {
                localStorage.setItem(LOCAL_STORAGE_KEYS.MY_ENV_TOKEN, resetToken);
            }
            return isResetTokenValid;
        }
        return false;
    }

    // eslint-disable-next-line max-lines-per-function
    public render(): JSX.Element | null {
        const {
            spec,
            permission,
            className,
            rootValue,
            alerts,
            options,
            context,
            mandatoryFields,
            guestFormState,
            userLoginInterface,
            isUserLoginWidget,
            isGuestInterfaceWidget,
            submitOptions,
            userLoginFormState,
            dynamicFilter,
            availabilityState,
        } = this.props;
        const {
            showExistingReservationFeedback,
            isRedirected,
            loginStatus,
            restrictedOption,
            isResetTokenValid,
            forgotPasswordStatus,
            updatePasswordStatus,
            url,
            ruleDefinitions,
            loginSuccessContent,
            templateChildren,
            failedLoginCount,
            fallbackWebContent,
            fallbackTemplate,
            redirectUrl,
        } = this.state;
        const { currentLocale, site } = context;
        const specId = spec.id;
        const draftValue = isUserLoginWidget ? userLoginFormState.draftValue?.[specId] || {} : guestFormState.draftValue;
        const validations = isUserLoginWidget ? userLoginFormState.validations?.[specId] || {} : guestFormState.validations;
        const { forgotPasswordInterface, updatePasswordInterface, updatePasswordAfterUserLoggedIn, useWithActivityPlanner, sessionExpiredFallback, enableDynamicTermsAndConditions, file } = options;
        const isValidationCheck = updatePasswordInterface && ruleDefinitions && Object.keys(ruleDefinitions).some((rule: RuleNames) => !ruleDefinitions[rule].valid);
        const localeId = context.currentLocale.locale;
        const disableWidget = !isClientLoggedIn();
        const hideWidget = getHideWidgetClass(options, disableWidget);
        const localContent = options.localized ? options.localized.find((lc) => lc.locale === localeId) : undefined;
        const isAllValid = this.isFormValid(validations);
        const shouldShowDownloadUrl = enableDynamicTermsAndConditions || localContent?.file?.document || options.file?.document;
        let isTermsAndConditionsTemplate = false;
        const resortId = getResortIdFromFilters(dynamicFilter, availabilityState);
        if (!localContent?.file?.document && !file?.document && enableDynamicTermsAndConditions && resortId) {
            isTermsAndConditionsTemplate = true;
        }
        if (isRedirected && redirectUrl) {
            redirectToPostReservationCreationPage(redirectUrl);
        }
        if (updatePasswordInterface && !updatePasswordAfterUserLoggedIn) {
            if (updatePasswordStatus === UpdatePasswordStatus.INITIAL && !isResetTokenValid) {
                if (sessionExpiredFallback && (fallbackTemplate || fallbackWebContent)) {
                    return renderNoResultsFoundContent({ noResultsFoundWebContent: fallbackWebContent, noResultsFoundTemplate: fallbackTemplate, context });
                }
                return (
                    <div className="alert alert-warning feedback">
                        <span>{getI18nLocaleString(namespaceList.userLoginWidget, "resetTokenInVaild", currentLocale, site)}</span>
                    </div>
                );
            } else if (!updatePasswordAfterUserLoggedIn && updatePasswordStatus === UpdatePasswordStatus.SUCCESS) {
                const localContent = getLocalizedContent({ site, currentLocale, localizedContent: options.localized });
                return (
                    <React.Fragment>
                        <div className="alert alert-success feedback">
                            <span>{localContent?.passwordUpdated}</span>
                        </div>
                        {url && <SmartLink href={url}>{localContent?.loginPageLinkLabel}</SmartLink>}
                        {options.loginAfterUpdatePassword && (
                            <>
                                <div className="alert alert-info feedback">
                                    <FontAwesome name="spinner" className={"searchfacet-progress in-progress"} />
                                    <span>{getI18nLocaleString(namespaceList.userLoginWidget, "redirectAfterUpdatePassword", currentLocale, site)}</span>
                                </div>
                            </>
                        )}
                    </React.Fragment>
                );
            }
        }
        return (
            <div>
                {showExistingReservationFeedback ? null : (
                    <Form className={className}>
                        <div
                            className={classNames("form-elements content-box", {
                                [`input-border-${options.borderSide}-${options.borderWidth}`]: options.addBorder && !!options.borderWidth,
                                [`input-border-radius-${options.borderRadius}`]: options.addBorderRadius && options.borderRadius !== "0",
                            })}
                        >
                            {options.showSources && !!this.state.sources && (
                                <div className="select-source">
                                    <label className="select-source__title">{getI18nLocaleString(namespaceList.admin, "selectSource", currentLocale, site)}</label>
                                    <Input type="select" value={this.state.selectedSourceId} onChange={this.sourceSelected}>
                                        <option>{getI18nLocaleString(namespaceList.admin, "selectSourcefromTheList", currentLocale, site)}</option>
                                        {this.state.sources.map((source: any) => (
                                            <option key={`${source.name}`} value={source.sourceId}>
                                                {source.name}
                                            </option>
                                        ))}
                                    </Input>
                                </div>
                            )}
                            {/* eslint-disable-next-line max-lines-per-function */}
                            {spec.properties.map((prop, ind) => {
                                if (!(options.fillCustomerDetails && loginStatus === LoginStatus.SUCCESS)) {
                                    if ((prop as any).spec && (prop as any).spec.id === "flexbox") {
                                        return (
                                            <Identity key={ind}>
                                                <FlexboxBase
                                                    id={(prop as any).id}
                                                    options={(prop as any).options}
                                                    children={(prop as any).children}
                                                    context={context}
                                                    className={(prop as any).className}
                                                    left={(prop as any).left}
                                                    right={(prop as any).right}
                                                    mode="edit"
                                                    root={draftValue}
                                                    item={draftValue}
                                                    initialItem={rootValue}
                                                    onChange={this.onPropChange}
                                                    alerts={alerts}
                                                    validate={this.validateInput}
                                                    validations={validations}
                                                    permission={permission}
                                                />
                                            </Identity>
                                        );
                                    } else if ((prop as any).spec && (prop as any).spec.id === "revealer") {
                                        /* jscpd:ignore-start */
                                        return (
                                            <Identity key={ind}>
                                                <Revealer
                                                    id={(prop as any).id}
                                                    childs={(prop as any).childs}
                                                    options={(prop as any).options}
                                                    showLess={(prop as any).showLess}
                                                    showMore={(prop as any).showMore}
                                                    className={(prop as any).className}
                                                    mode="edit"
                                                    root={draftValue}
                                                    item={draftValue}
                                                    initialItem={rootValue}
                                                    onChange={this.onPropChange}
                                                    formAlerts={alerts}
                                                    validate={this.validateInput}
                                                    validations={validations}
                                                    permission={permission}
                                                />
                                            </Identity>
                                        );
                                        /* jscpd:ignore-end */
                                    } else if ((prop as any).spec && (prop as any).spec.id === "webcontent") {
                                        return (
                                            <Identity key={ind}>
                                                <WebContent
                                                    id={(prop as any).id}
                                                    options={(prop as any).options}
                                                    content={(prop as any).content}
                                                    imageUrl={(prop as any).imageUrl}
                                                    cardImageUrl={(prop as any).cardImageUrl}
                                                    sitePageUrl={(prop as any).sitePageUrl}
                                                    friendlyName={(prop as any).friendlyName}
                                                    alt={(prop as any).alt}
                                                    className={(prop as any).className}
                                                    context={(prop as any).context}
                                                    env={(prop as any).env}
                                                />
                                            </Identity>
                                        );
                                    }
                                    return (
                                        <div className="input-container" key={String(prop.variable)}>
                                            <GenericInput
                                                spec={prop}
                                                item={draftValue}
                                                value={draftValue[prop.variable] || undefined}
                                                mode={"edit"}
                                                permission={permission}
                                                root={draftValue}
                                                initialItem={rootValue}
                                                onChange={this.onPropChange}
                                                alerts={alerts}
                                                validate={this.validateInput}
                                            />
                                            {validations && validations[prop.variable as string] ? (
                                                !validations[prop.variable as string].isValid ? (
                                                    <p className="input-validation-message">{validations[prop.variable as string].message}</p>
                                                ) : null
                                            ) : null}
                                            {prop.useAsConfirmPassword && draftValue.password && (
                                                <ul className="password-checklist">
                                                    {ruleDefinitions &&
                                                        Object.keys(ruleDefinitions).map((rule: RuleNames, index) => {
                                                            const { message, valid } = ruleDefinitions[rule];
                                                            return (
                                                                <li key={index} className="list">
                                                                    <FontAwesome
                                                                        className={`checklist-icon ${valid ? " valid" : "invalid"}`}
                                                                        name={valid ? "check-circle-o" : "exclamation-circle"}
                                                                    ></FontAwesome>
                                                                    <span className="checklist-label">{message}</span>
                                                                </li>
                                                            );
                                                        })}
                                                </ul>
                                            )}
                                        </div>
                                    );
                                }
                            })}
                        </div>
                        {options.showConditionCheckBox && (
                            <div className={"version-table-custom-padding"}>
                                <div className="general-conditions">
                                    <Label className="general-conditions__label">
                                        <Input className={"general-conditions__checkbox"} type="checkbox" checked={guestFormState.agreeTerms} onChange={this.handleSelectTerms} />
                                        <span>
                                            {getI18nLocaleString(namespaceList.admin, "generalConditionsText", currentLocale, site)}
                                            {shouldShowDownloadUrl && (
                                                <a className="general-conditions__link" onClick={this.handleDownloadClick} rel="nofollow">
                                                    {isTermsAndConditionsTemplate ? (
                                                        <small>{getI18nLocaleString(namespaceList.admin, "view", currentLocale, site)}</small>
                                                    ) : (
                                                        <small>{getI18nLocaleString(namespaceList.admin, "downloadGeneralConditionsLabel", currentLocale, site)}</small>
                                                    )}
                                                </a>
                                            )}
                                        </span>
                                    </Label>

                                    <Modal isOpen={this.state.isTermsAndConditionsModalOpen} toggleTermsModal={this.toggleTermsModal} className="page-modal" size="md">
                                        <ModalHeader tag={"h4"}>{getI18nLocaleString(namespaceList.admin, "termsAndConditions", currentLocale, site)}</ModalHeader>
                                        <ModalBody>
                                            <div dangerouslySetInnerHTML={{ __html: this.state.termsHtmlString }}></div>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button className="button button--primary" onClick={this.toggleTermsModal}>
                                                <FontAwesome name="check" />
                                                <label onClick={this.setTermsAgreement}>{getI18nLocaleString(namespaceList.admin, "cookieButtonAccept", currentLocale, site)}</label>
                                            </Button>
                                        </ModalFooter>
                                    </Modal>
                                </div>
                            </div>
                        )}
                        {options.newsLetterCheckBox && (
                            <div className={"version-table-custom-padding"}>
                                <div>
                                    <Label>
                                        <Input className={""} type="checkbox" onChange={this.allowEmails} />
                                        {localContent && localContent.newsLetterText ? localContent.newsLetterText : getI18nLocaleString(namespaceList.admin, "newsLetterText", currentLocale, site)}
                                    </Label>
                                </div>
                            </div>
                        )}
                        {submitOptions && (
                            <div className="custom-form-submit-button">
                                <Button
                                    className="submit"
                                    type="submit"
                                    color="primary"
                                    onClick={this.customOnSubmit.bind(this, submitOptions, draftValue)}
                                    href="#"
                                    disabled={!isAllValid || this.state.isLoading || isValidationCheck}
                                >
                                    <FontAwesome name="spinner" className={classNames("searchfacet-progress", this.isLoading() ? "in-progress" : "no-progress")} />
                                    <Label>{localContent?.buttonText}</Label>
                                    <FontAwesome name="spinner" className={"searchfacet-progress no-progress"} />
                                </Button>
                            </div>
                        )}
                        {!isGuestInterfaceWidget && !submitOptions && (
                            <div className="user-login-button">
                                {
                                    <RevealerContext.Consumer>
                                        {({ revealerToggle }) => (
                                            <Button
                                                className="submit"
                                                type="submit"
                                                color="primary"
                                                // eslint-disable-next-line max-len
                                                onClick={this.onClickHandler.bind(this, revealerToggle)}
                                                href="#"
                                                title={getI18nLocaleString(
                                                    namespaceList.admin,
                                                    userLoginInterface || forgotPasswordInterface || updatePasswordInterface ? this.getFormLabel() : "bookNow",
                                                    currentLocale,
                                                    site
                                                )}
                                                disabled={
                                                    (userLoginInterface || forgotPasswordInterface || updatePasswordInterface || useWithActivityPlanner
                                                        ? (loginStatus !== LoginStatus.SUCCESS && !isAllValid) ||
                                                          loginStatus === LoginStatus.LOADING ||
                                                          this.state.isLoading ||
                                                          isValidationCheck ||
                                                          (userLoginInterface && loginStatus !== LoginStatus.SUCCESS && !draftValue.password)
                                                        : !mandatoryFields.every((field) => !!draftValue[field]) ||
                                                          (options.showConditionCheckBox ? !draftValue.agreeTerms : true) ||
                                                          this.isLoading()) || restrictedOption === RESTRICTION_RULE_OPTIONS.BOOK_RESTRICTION
                                                }
                                            >
                                                <FontAwesome
                                                    name="spinner"
                                                    className={classNames(
                                                        "searchfacet-progress",
                                                        (this.isLoading() || (userLoginInterface && loginStatus === LoginStatus.LOADING)) &&
                                                            restrictedOption !== RESTRICTION_RULE_OPTIONS.BOOK_RESTRICTION
                                                            ? "in-progress"
                                                            : "no-progress"
                                                    )}
                                                />
                                                <Label>
                                                    {(userLoginInterface || forgotPasswordInterface || updatePasswordInterface) && localContent?.buttonText && !(loginStatus === LoginStatus.SUCCESS)
                                                        ? localContent?.buttonText
                                                        : getI18nLocaleString(
                                                              namespaceList.admin,
                                                              userLoginInterface || forgotPasswordInterface ? this.getFormLabel() : "bookNow",
                                                              currentLocale,
                                                              site
                                                          )}
                                                </Label>
                                                <FontAwesome name="spinner" className={"searchfacet-progress no-progress"} />
                                            </Button>
                                        )}
                                    </RevealerContext.Consumer>
                                }
                                {this.isReservationCreationFailed() && (
                                    <div className="alert alert-warning feedback">
                                        <span>{getI18nLocaleString(namespaceList.widgetPrevNext, "reservationCreationError", currentLocale, site)}</span>
                                    </div>
                                )}
                                {userLoginInterface && isLoginFailed(loginStatus) && (
                                    <div className="alert alert-warning feedback">
                                        {loginStatus === LoginStatus.FAILED && (
                                            <span>{getI18nLocaleString(namespaceList.admin, failedLoginCount < 5 ? "loginFailed" : "loginFailedManyTimes", currentLocale, site)}</span>
                                        )}
                                        {loginStatus === LoginStatus.FAILED_TOO_MANY_RETRIES && (
                                            <span>{getI18nLocaleString(namespaceList.admin, "loginFailedBlockedByTooManyRetries", currentLocale, site)}</span>
                                        )}
                                    </div>
                                )}
                                {(forgotPasswordInterface || updatePasswordInterface) &&
                                    (forgotPasswordStatus !== ForgotPasswordStatus.INITIAL || updatePasswordStatus !== UpdatePasswordStatus.INITIAL) &&
                                    this.getFeedbackMessage()}
                                {(!this.isReservationCreationFailed() || !isLoginFailed(loginStatus)) &&
                                    (restrictedOption === RESTRICTION_RULE_OPTIONS.ROLLING_REQUEST || restrictedOption === RESTRICTION_RULE_OPTIONS.REQUEST) && (
                                        <div className="alert alert-info feedback">
                                            <span>{getI18nLocaleString(namespaceList.widgetPrevNext, "onRequestReservationInfo", currentLocale, site)}</span>
                                        </div>
                                    )}
                                {restrictedOption === RESTRICTION_RULE_OPTIONS.BOOK_RESTRICTION && (
                                    <div className="alert alert-warning feedback">
                                        <span>{getI18nLocaleString(namespaceList.widgetPrevNext, "restrictionError", currentLocale, site)}</span>
                                    </div>
                                )}
                                {options.fillCustomerDetails && loginSuccessContent && loginStatus === LoginStatus.SUCCESS && templateChildren && (
                                    <TemplateWidget
                                        hideWidget={hideWidget || ""}
                                        classNames={className || ""}
                                        template={loginSuccessContent}
                                        options={{ ...options, templateId: loginSuccessContent._id, layHierarchy: false }}
                                        // eslint-disable-next-line react/no-children-prop
                                        children={templateChildren}
                                    />
                                )}
                            </div>
                        )}
                    </Form>
                )}
            </div>
        );
    }

    private async setRedirectUrl() {
        const { billState, context, options } = this.props;
        const { useWithActivityPlanner, freeActivityPageId } = options;
        const localeId = context.currentLocale.locale;
        const externalRedirectUrl = options?.localized ? options.localized.find((lc) => lc.locale === localeId)?.externalRedirectUrl : "";
        let linkOrPageId: string | undefined = options.pageId;
        let isFreeActivity = false;
        if (useWithActivityPlanner && (freeActivityPageId || options.useExternalRedirectUrl)) {
            const isActivityPaidOnArrival = this.props.dynamicFilter.selectedActivities?.find((activity) => activity.resourceActivity.productTypeResortArticle);
            const billTotalLine = billState.mainBill?.customerBill.filter((billLine) => billLine.billLineType === MXTS.BillLineType.TOTAL);
            if ((billTotalLine?.length && billTotalLine[0]?.total === 0) || isActivityPaidOnArrival) {
                linkOrPageId = options.useExternalRedirectUrl
                    ? externalRedirectUrl
                    : freeActivityPageId && (await pageLink({ site: context.site, pageId: freeActivityPageId, locale: context.currentLocale, context }));
                isFreeActivity = true;
            }
        }
        if (!isFreeActivity) {
            if (options.useExternalPaymentUrl) {
                linkOrPageId = options?.localized ? options.localized.find((lc) => lc.locale === localeId)?.externalUrl : "";
            } else if (linkOrPageId) {
                linkOrPageId = await pageLink({ site: context.site, pageId: linkOrPageId, locale: context.currentLocale, context });
            }
        }
        const redirectUrl = `${linkOrPageId}?${window.location.href.split("?")[1]}`;
        this.setState({ isRedirected: true, redirectUrl });
    }

    private getReCaptchaToken = async (): Promise<string> => {
        const executeRecaptcha = (this.props as IWithGoogleReCaptchaProps)?.googleReCaptchaProps?.executeRecaptcha;
        try {
            if (executeRecaptcha) {
                return executeRecaptcha("submit").catch((err) => {
                    console.log("Failed to verify recaptcha", err);
                    return "";
                });
            }
        } catch (err) {
            console.error(err);
        }
        return "";
    };

    private handleSelectTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { draftValue } = this.props.guestFormState;
        const { checked } = event.target;
        this.props.dispatchAction({
            type: ActionType.GuestForm,
            actionType: GuestFormActionType.updateAgreeTerms,
            payload: { draftValue: { ...draftValue, login: draftValue.email }, agreeTerms: checked },
        });
    };
    private dispatchFormAction = (type: ActionType, actionType: UserLoginFormActionType | GuestFormActionType, emailAllowed: boolean) => {
        const { guestFormState, userLoginFormState, isUserLoginWidget, spec } = this.props;
        const specId = spec.id;
        const draftValue = isUserLoginWidget ? userLoginFormState.draftValue?.[specId] || {} : guestFormState.draftValue;
        this.props.dispatchAction({
            type,
            actionType,
            payload: isUserLoginWidget ? { draftValue, specId } : { draftValue: { ...draftValue, emailAllowed } },
        });
    };
    private getSessionExpiredFallback = async () => {
        const { sessionExpiredFallbackWebContentId, sessionExpiredFallbackTemplateId } = this.props.options;
        if (sessionExpiredFallbackTemplateId) {
            const fallbackTemplate = await getNoDataFoundTemplate(sessionExpiredFallbackTemplateId, this.props.context);
            this.setState({ fallbackTemplate });
        }
        if (sessionExpiredFallbackWebContentId) {
            const fallbackWebContent = await getNoDataFoundContent(sessionExpiredFallbackWebContentId);
            this.setState({ fallbackWebContent });
        }
    };
    private allowEmails = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { isUserLoginWidget } = this.props;
        const emailAllowed = event.target.checked;
        if (isUserLoginWidget) {
            this.dispatchFormAction(ActionType.UserLoginForm, UserLoginFormActionType.change, emailAllowed);
        } else {
            this.dispatchFormAction(ActionType.GuestForm, GuestFormActionType.change, emailAllowed);
        }
    };

    private validateAllInputs = () => {
        const { spec } = this.props;
        spec.properties.map((property) => {
            if ((property as any).children?.length) {
                (property as any).children.forEach((child: any) => this.validateInput(child.variable, true));
            }
        });
    };

    private setTermsAgreement = () => {
        const { draftValue } = this.props.guestFormState;
        this.props.dispatchAction({
            type: ActionType.GuestForm,
            actionType: GuestFormActionType.updateAgreeTerms,
            payload: { draftValue: { ...draftValue, login: draftValue.email }, agreeTerms: true },
        });
    };

    private validateInput = (id: string, isValid: boolean) => {
        const { spec, mandatoryFields, isUserLoginWidget } = this.props;
        const inputWidget: any = spec.properties.find((prop) => {
            if ((prop as any).children) {
                return !!(prop as any).children.find((child: any) => child.variable === id);
            }
            return prop.variable === id;
        });
        const message = inputWidget.children ? inputWidget.children.find((child: any) => child.variable === id).message : inputWidget.message;
        this.setState({ validations: { ...this.state.validations, [id]: { isValid, message } } });
        const type = isUserLoginWidget ? ActionType.UserLoginForm : ActionType.GuestForm;
        const actionType = isUserLoginWidget ? UserLoginFormActionType.validate : GuestFormActionType.validate;
        this.props.dispatchAction({
            type,
            actionType,
            payload: {
                validations: { [id]: { isValid, message } },
                mandatoryFields,
                ...(isUserLoginWidget && { specId: spec.id }),
            },
        });
    };

    private getFormLabel = () => {
        const { userLoginInterface } = this.props;
        const { loginStatus } = this.state;
        if (userLoginInterface) {
            if (loginStatus === LoginStatus.SUCCESS) {
                return "userLogout";
            }
            return "userLogin";
        }
        return "submit";
    };

    private onClickHandler = (revealerToggle: () => void) => {
        const { userLoginInterface, options } = this.props;
        const { forgotPasswordInterface, updatePasswordInterface } = options;
        if (userLoginInterface) {
            return this.handleUserLogin(revealerToggle);
        } else if (forgotPasswordInterface) {
            return this.handleForgotPasswordFlow();
        } else if (updatePasswordInterface) {
            return this.handleUpdatePasswordFlow();
        }
    };

    private customOnSubmit = async (submitOptions: FormSubmitOptions, draftValue: any) => {
        submitOptions.onSubmit({ reCaptchaToken: submitOptions.includeRecaptchaToken ? await this.getReCaptchaToken() : undefined, getRecaptchaToken: this.getReCaptchaToken, draftValue });
    };

    private toggleTermsModal = () => {
        this.setState({ isTermsAndConditionsModalOpen: !this.state.isTermsAndConditionsModalOpen });
    };

    private getCustomerLocale = async (languageId: number | null | undefined): Promise<CurrentLocale | undefined> => {
        if (languageId) {
            const env = await getMxtsEnv(this.props.context);
            const localeCode = (await this.props.context.mxtsApi.languages(env, { languageId }))[0].shortName;
            const locale: (Locale & WithId) | null = await LocaleApi.findByCode({
                code: localeCode,
            });
            if (locale) {
                return transformLocaleIntoCurrentLocale(locale);
            }
        }
    };

    // eslint-disable-next-line max-lines-per-function
    private handleUserLogin = async (revealerToggle?: () => void) => {
        const {
            guestFormState,
            isUserLoginWidget,
            userLoginFormState,
            context: {
                currentLocale: { locale: currentLocaleId, code: currentLocaleCode },
            },
            options: { fillCustomerDetails, loginAfterUpdatePassword },
            userLoginInterface,
            dynamicFilter,
            mandatoryFields,
            onLoginSuccess,
            spec,
        } = this.props;
        const draftValue = isUserLoginWidget ? userLoginFormState.draftValue?.[spec.id] || {} : guestFormState.draftValue;

        let tokenData: MXTS.MyEnvAuthTokenCustomerPayload | undefined;
        if (loginAfterUpdatePassword && this.state.isResetTokenValid) {
            const resetToken = getResetToken();
            tokenData = MXTS.parseMyEnvToken(resetToken);
        }

        const env = await getMxtsEnv(this.props.context, currentLocaleCode);
        if (this.state.loginStatus !== LoginStatus.SUCCESS) {
            this.setState({ loginStatus: LoginStatus.LOADING });

            if (fillCustomerDetails) {
                const customerLoginResult = await loginCustomer({
                    env,
                    email: tokenData?.email || draftValue.email,
                    password: draftValue.password,
                    includeAddress: !!fillCustomerDetails,
                    mxtsApi: this.props.context.mxtsApi,
                    cmsApi: this.props.context.cmsApi,
                });
                const customerData = customerLoginResult.customer;
                if (customerData) {
                    const parsedCustomerData = parseCustomer(customerData);
                    if (guestFormState.validations) {
                        const updateValidation = validateCustomerData(parsedCustomerData, guestFormState.validations);
                        this.props.dispatchAction({
                            type: ActionType.GuestForm,
                            actionType: GuestFormActionType.validate,
                            payload: { validations: updateValidation, mandatoryFields },
                        });
                    }

                    this.setState({ customerData, failedLoginCount: 0 }, async () => {
                        this.props.dispatchAction({
                            type: ActionType.GuestForm,
                            actionType: GuestFormActionType.change,
                            payload: { draftValue: parsedCustomerData },
                        });
                        this.setState({ loginStatus: LoginStatus.SUCCESS });
                    });
                    if (revealerToggle) {
                        revealerToggle();
                    }
                } else {
                    this.onFailedLogin(customerLoginResult);
                }
            } else {
                /* jscpd:ignore-start */
                const customerLoginResult = await loginCustomer({
                    env,
                    email: tokenData?.email || draftValue.email,
                    password: draftValue.password,
                    includeAddress: !!fillCustomerDetails,
                    mxtsApi: this.props.context.mxtsApi,
                    cmsApi: this.props.context.cmsApi,
                });
                const customerData = customerLoginResult.customer;
                if (customerData) {
                    this.setState({ customerData, failedLoginCount: 0 }, async () => {
                        const { owner: isOwner } = customerData;
                        let { localizedPageLink } = this.props.options;
                        if (userLoginInterface != null && isOwner) {
                            const { localizedOwnerRedirectPageLink } = this.props.options as UserLoginWidgetOptions;
                            localizedPageLink = localizedOwnerRedirectPageLink;
                        }

                        /* jscpd:ignore-end */
                        const localizedPage = localizedPageLink?.find((pageLink) => pageLink.locale === currentLocaleId);
                        const { siteId, pageId } = localizedPage || {};
                        const myEnvPreviousUrl = localStorage.getItem("myEnvPreviousUrl");
                        if (siteId && pageId) {
                            const { context } = this.props;
                            let sitePageUrl = "";
                            const site: (Site & WithId) | null = await context.cmsApi.siteApi.findById({ id: siteId, projection: { sitemap: 0 } });
                            sitePageUrl = sitePageUrl.concat("//");
                            let targetMyEnvRedirectUrl: string | undefined;
                            if (site && pageId) {
                                sitePageUrl = sitePageUrl.concat(site.host);
                                const url = (await pageLink({ site, pageId, locale: context.currentLocale, context })) || "";
                                if (this.props.options.isBookingFlowLoginRedirect) {
                                    const paramsFromFilter = dynamicFormUtil.getParamsFromFilter(dynamicFilter);
                                    const urlWithParams = paramsFromFilter && url && UrlParamsUtil.getBmUrlWithQueryParams(url, paramsFromFilter);
                                    sitePageUrl = sitePageUrl.concat(urlWithParams);
                                } else {
                                    sitePageUrl = sitePageUrl.concat(url ? url : "");
                                }
                                targetMyEnvRedirectUrl = sitePageUrl;
                            }
                            const loginPageUrl = await getLoginPageRedirectUrl(context);
                            const myEnvPreviousUrlIsLoginPage = loginPageUrl && myEnvPreviousUrl?.toLowerCase().includes(loginPageUrl.toLowerCase());
                            if (myEnvPreviousUrl && !isMyEnvPreviousUrlSameAsCurrent(myEnvPreviousUrl) && !myEnvPreviousUrlIsLoginPage) {
                                targetMyEnvRedirectUrl = myEnvPreviousUrl;
                            }
                            onLoginSuccess?.({ customer: customerData, targetMyEnvRedirectUrl });
                        }
                        this.setState({ loginStatus: LoginStatus.SUCCESS });
                    });
                } else {
                    this.onFailedLogin(customerLoginResult);
                }
            }
        } else {
            this.props.dispatchAction({
                type: ActionType.GuestForm,
                actionType: GuestFormActionType.change,
                payload: { draftValue: {} },
            });
            const myEnvPreviousUrl = localStorage.getItem("myEnvPreviousUrl");
            if (myEnvPreviousUrl) {
                localStorage.removeItem("myEnvPreviousUrl");
            }
            localStorage.removeItem(LOCAL_STORAGE_KEYS.MY_ENV_TOKEN);
            this.setState({ loginStatus: LoginStatus.INITIAL, validations: {} });
        }
    };

    private onFailedLogin(customerLoginResult: CustomerLoginResult) {
        const { userLoginFormState, isUserLoginWidget, guestFormState, spec } = this.props;
        const specId = spec.id;
        const draftValue = isUserLoginWidget ? userLoginFormState.draftValue?.[specId] || {} : guestFormState.draftValue;
        this.setState({ loginStatus: customerLoginResult.errorType === "tooManyTries" ? LoginStatus.FAILED_TOO_MANY_RETRIES : LoginStatus.FAILED, failedLoginCount: this.state.failedLoginCount + 1 });
        this.props.dispatchAction({
            type: ActionType.UserLoginForm,
            actionType: UserLoginFormActionType.change,
            payload: { draftValue: { ...draftValue, password: "" }, specId },
        });
    }

    private getFeedbackMessage = () => {
        const {
            options,
            context: { currentLocale, site },
        } = this.props;
        const { forgotPasswordInterface, updatePasswordInterface, localizedFormOptions, updatePasswordAfterUserLoggedIn } = options;
        const { forgotPasswordStatus, updatePasswordStatus } = this.state;
        const localContent = getLocalizedContent({ site, currentLocale, localizedContent: localizedFormOptions || [] });
        if (forgotPasswordInterface) {
            if (forgotPasswordStatus === ForgotPasswordStatus.CUSTOMER_NOT_FOUND) {
                return (
                    <div className="alert alert-warning feedback">
                        <span>{getI18nLocaleString(namespaceList.userLoginWidget, "customerNotFound", currentLocale, site)}</span>
                    </div>
                );
            } else if (forgotPasswordStatus === ForgotPasswordStatus.FAILED) {
                return (
                    <div className="alert alert-danger feedback">
                        <span>{getI18nLocaleString(namespaceList.userLoginWidget, "failedToSendEmail", currentLocale, site)}</span>
                    </div>
                );
            } else if (forgotPasswordStatus === ForgotPasswordStatus.SUCCESS) {
                return (
                    <div className="alert alert-info feedback">
                        <span>{localContent?.feedbackMessage}</span>
                    </div>
                );
            }
        }
        if (updatePasswordInterface) {
            if (updatePasswordStatus === UpdatePasswordStatus.FAILED || updatePasswordStatus === UpdatePasswordStatus.FAILED_PW_SAME_AS_OLD) {
                return (
                    <div className="alert alert-danger feedback">
                        <span>
                            {getI18nLocaleString(
                                namespaceList.userLoginWidget,
                                updatePasswordStatus === UpdatePasswordStatus.FAILED ? "updatePasswordFailed" : "newPasswordSameAsOld",
                                currentLocale,
                                site
                            )}
                        </span>
                    </div>
                );
            } else if (updatePasswordAfterUserLoggedIn && updatePasswordStatus === UpdatePasswordStatus.SUCCESS) {
                const passwordUpdatedFeedback = getLocalizedContent({ site, currentLocale, localizedContent: options.localized })?.passwordUpdated;
                return (
                    <div className="alert alert-success feedback">
                        <span>{passwordUpdatedFeedback}</span>
                    </div>
                );
            }
        }
    };

    private redirectAlreadyLoggedInUser = async (myEnvToken: string | null, env: MXTS.ApiCallOptions) => {
        const {
            context: {
                currentLocale: { locale: currentLocaleId },
            },
            userLoginInterface,
        } = this.props;
        const myEnvAuthTokenData = MXTS.parseMyEnvToken(myEnvToken);
        const customerData = await MXTS.MxtsApi.getCustomer(env, {}, [{ key: "customerId", value: myEnvAuthTokenData?.mainCustomerId }]);
        const { owner: isOwner } = customerData;
        let { localizedPageLink } = this.props.options;
        if (userLoginInterface != null && isOwner) {
            const { localizedOwnerRedirectPageLink } = this.props.options as UserLoginWidgetOptions;
            localizedPageLink = localizedOwnerRedirectPageLink;
        }
        const customerLocale = await this.getCustomerLocale(customerData.languageId);
        const newCurrentLocaleId = customerLocale?.locale || currentLocaleId;
        const localizedPage = localizedPageLink?.find((pageLink) => pageLink.locale === newCurrentLocaleId);
        const { siteId, pageId } = localizedPage || {};
        if (siteId && pageId) {
            const { context } = this.props;
            let sitePageUrl = "";
            const [site, customerLocale]: [(Site & WithId) | null, CurrentLocale | undefined] = await Promise.all([
                context.cmsApi.siteApi.findById({ id: siteId, projection: { sitemap: 0 } }),
                this.getCustomerLocale(customerData.languageId),
            ]);
            sitePageUrl = sitePageUrl.concat("//");
            if (site && pageId) {
                sitePageUrl = sitePageUrl.concat(site.host);
                const url = (await pageLink({ site, pageId, locale: customerLocale || context.currentLocale, context })) || "";
                sitePageUrl = sitePageUrl.concat(url ? url : "");
                location.replace(sitePageUrl);
            }
        }
    };

    private handleForgotPasswordFlow = async () => {
        const { options, context, userLoginFormState, isUserLoginWidget, guestFormState, spec } = this.props;
        const draftValue = isUserLoginWidget ? userLoginFormState.draftValue?.[spec.id] || {} : guestFormState.draftValue;

        const emailId = draftValue.email;
        this.setState({ isLoading: true, forgotPasswordStatus: ForgotPasswordStatus.INITIAL });
        if (emailId) {
            try {
                const reCaptchaToken = await this.getReCaptchaToken();
                const resetLinkSent = await dynamicFormUtil.handleForgotPasswordFlow({ context, options, email: emailId, reCaptchaToken });
                if (resetLinkSent) {
                    this.setState({ isLoading: false, forgotPasswordStatus: ForgotPasswordStatus.SUCCESS });
                } else {
                    this.setState({ forgotPasswordStatus: ForgotPasswordStatus.FAILED, isLoading: false });
                }
            } catch (error) {
                // Handle any errors during the forget password process
                globalApiContext().logger.error(`Error in forgot password flow: ${error}`);
                this.setState({ forgotPasswordStatus: ForgotPasswordStatus.FAILED, isLoading: false });
            }
        } else {
            this.setState({ isLoading: false, forgotPasswordStatus: ForgotPasswordStatus.CUSTOMER_NOT_FOUND });
        }
    };

    private handleUpdatePasswordFlow = async () => {
        const { options, userLoginFormState, isUserLoginWidget, guestFormState, context, myEnvState, dispatchAction, spec } = this.props;
        const draftValue = isUserLoginWidget ? userLoginFormState.draftValue?.[spec.id] || {} : guestFormState.draftValue;

        const env = await getMxtsEnv(context);
        const isResetTokenValid = await this.checkResetTokenValid();
        this.setState({ isLoading: true, isResetTokenValid, updatePasswordStatus: UpdatePasswordStatus.INITIAL });
        if (!options.updatePasswordAfterUserLoggedIn && isResetTokenValid) {
            const resetToken = getResetToken();
            const tokenData: MXTS.MyEnvAuthTokenCustomerPayload | undefined = MXTS.parseMyEnvToken(resetToken);
            if (tokenData?.customerIds && !!(await context.mxtsApi.isMyEnvAuthTokenValid(env, { token: resetToken || "" }).catch(() => null))) {
                const login = tokenData?.email;
                const password = draftValue.password;
                const customerId = tokenData.mainCustomerId;
                if (login && password && customerId) {
                    /* jscpd:ignore-start */
                    const isCustomerCredentialsUpdated: { success: boolean; errorCode?: number } = await context.mxtsApi
                        .updateCustomerCredentials(env, { login, password }, [{ key: "customerId", value: customerId }])
                        .then(() => ({ success: true }))
                        .catch((error) => {
                            console.warn("Failed to update credentials", error);
                            return { success: false, errorCode: error?.status };
                        });
                    /* jscpd:ignore-end */
                    if (isCustomerCredentialsUpdated.success) {
                        this.setState({ updatePasswordStatus: UpdatePasswordStatus.SUCCESS });
                        const customer = await context.mxtsApi.getCustomer(env, { view: "detail" }, [{ key: "customerId", value: customerId }]);
                        const customerLocale = await this.getCustomerLocale(customer.languageId);
                        const currentLocale = customerLocale || context.currentLocale;
                        const localizedPage = getLocalizedContent({ currentLocale, site: context.site, localizedContent: options.localizedPageLink || [] });
                        const { siteId, pageId } = localizedPage || {};
                        if (siteId && pageId && !options.loginAfterUpdatePassword) {
                            const site = await context.cmsApi.siteApi.findById({ id: siteId, projection: { sitemap: 0 } });
                            if (site) {
                                const url = await pageLink({ site, pageId, locale: currentLocale, context });
                                if (url) {
                                    this.setState({ url });
                                }
                            }
                        }
                        if (options.loginAfterUpdatePassword) {
                            this.handleUserLogin();
                        }
                    } else {
                        this.setState({ updatePasswordStatus: isCustomerCredentialsUpdated.errorCode === 422 ? UpdatePasswordStatus.FAILED_PW_SAME_AS_OLD : UpdatePasswordStatus.FAILED });
                    }
                }
            }
            this.setState({ isResetTokenValid: false });
        }
        if (options.updatePasswordAfterUserLoggedIn) {
            const customer = getMyEnvMainCustomer({ myEnvState, dispatchAction });
            const filteredSpec = spec.properties.find((item) => item.useAsOldPassword);
            const oldPassword = draftValue[filteredSpec?.variable];
            const customerEmail = getCustomerMailAddress(customer);
            if (customer && customerEmail && oldPassword) {
                const password = draftValue.password;
                const obtainedCustomer = await getCustomerDetailsByLogin({ env, email: customerEmail, password: oldPassword, includeAddress: false, mxtsApi: this.props.context.mxtsApi });
                if (obtainedCustomer) {
                    const isCustomerCredentialsUpdated: { success: boolean; errorCode?: number } = await context.mxtsApi
                        .updateCustomerCredentials(env, { login: customerEmail, password }, [{ key: "customerId", value: customer.customerId }])
                        .then(() => ({ success: true }))
                        .catch((error) => {
                            console.warn("Failed to update credentials", error);
                            return { success: false, errorCode: error?.status };
                        });
                    if (isCustomerCredentialsUpdated.success) {
                        this.setState({ updatePasswordStatus: UpdatePasswordStatus.SUCCESS });
                    } else {
                        this.setState({ updatePasswordStatus: isCustomerCredentialsUpdated.errorCode === 422 ? UpdatePasswordStatus.FAILED_PW_SAME_AS_OLD : UpdatePasswordStatus.FAILED });
                    }
                } else {
                    this.setState({ updatePasswordStatus: UpdatePasswordStatus.FAILED });
                }
            }
        }
        this.setState({ isLoading: false });
    };

    private handleAutoFillCustomerDetails = async () => {
        const { context } = this.props;
        const token = localStorage.getItem(LOCAL_STORAGE_KEYS.MY_ENV_TOKEN);
        if (token) {
            const customerId = await getMainCustomerIdFromLoginToken();
            const env = await getMxtsEnv(context);
            if (customerId) {
                const customerDetails = await context.mxtsApi.getCustomer(env, { view: "detail" }, [{ key: "customerId", value: customerId }]);
                const parsedCustomerDetails = parseCustomer(customerDetails);
                this.props.dispatchAction({
                    type: ActionType.GuestForm,
                    actionType: GuestFormActionType.change,
                    payload: { draftValue: parsedCustomerDetails },
                });
                this.validateAllInputs();
            }
        }
    };

    private getsources = async () => {
        const env = await getMxtsEnv(this.props.context);
        const sources = await this.props.context.mxtsApi.source(env, { size: 75 });
        this.setState({ sources: sources.content });
    };

    private sourceSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ selectedSourceId: event.target.value });
    };

    private handleEnterKeypress = (event: KeyboardEvent) => {
        const { spec, userLoginFormState } = this.props;
        if (event.key === "Enter") {
            event.preventDefault();
            if (this.isFormValid(userLoginFormState.validations?.[spec.id] || {})) {
                this.handleUserLogin();
            }
        }
    };

    private isFormValid = (validations?: {
        [id: string]: {
            isValid: boolean;
            message?: string;
        };
    }): boolean => {
        const validationsNotEmpty = validations && Object.keys(validations).length > 0;
        const isAllValid = validations && validationsNotEmpty && Object.keys(validations).every((validationKey) => validations[validationKey].isValid);
        return !!isAllValid;
    };

    private onPropChange = (newVal: any, prop: InputSpec<E, keyof E>) => {
        const { isUserLoginWidget, guestFormState, userLoginFormState, spec, context } = this.props;
        if (isUserLoginWidget) {
            const userLoginElement = document.getElementById(prop.type);
            userLoginElement?.addEventListener("keypress", this.handleEnterKeypress);
        }
        const specId = spec.id;
        const currentDraftValue = isUserLoginWidget ? userLoginFormState.draftValue?.[specId] || {} : guestFormState.draftValue;
        const draftValue = { ...currentDraftValue, [prop.variable as string]: newVal };
        const dispatchType = isUserLoginWidget ? ActionType.UserLoginForm : ActionType.GuestForm;
        const actionType = isUserLoginWidget ? UserLoginFormActionType.change : GuestFormActionType.updateDraftValues;

        this.props.dispatchAction({
            type: dispatchType,
            actionType,
            payload: isUserLoginWidget ? { draftValue, specId } : { draftValue: { [prop.variable as string]: newVal } },
        });
        const filteredSpec = spec.properties.find((item) => item.useAsConfirmPassword);
        const confirmPassword = draftValue[filteredSpec?.variable];
        if (filteredSpec?.useAsConfirmPassword && draftValue.password) {
            const ruleDefinitions = dynamicFormUtil.passwordChecklist(draftValue.password, confirmPassword, context);
            this.setState({ ruleDefinitions });
            const isValidationCheckList = (ruleDefinitions && Object.keys(ruleDefinitions).some((rule: RuleNames) => !ruleDefinitions[rule].valid)) || false;
            this.props.dispatchAction({
                type: dispatchType,
                actionType: isUserLoginWidget ? UserLoginFormActionType.isValidationCheckList : GuestFormActionType.isValidationCheckList,
                payload: { isValidationCheckList },
            });
        }
    };

    private downloadUrl = async () => {
        const { options, context, dynamicFilter, availabilityState } = this.props;
        const localeId = context.currentLocale.locale;
        const localContent = options.localized ? options.localized.find((lc) => lc.locale === localeId) : undefined;
        const env = await getMxtsEnv(context, context.currentLocale.code);
        const resortId = getResortIdFromFilters(dynamicFilter, availabilityState);

        if (resortId && options.enableDynamicTermsAndConditions) {
            let tncTemplates: MXTS.TemplateMessageRenderResult[] | undefined = await MXTS.MxtsApi.getTermAndConditionsTemplate(env, { resortid: resortId, size: 1 }).catch(() => undefined);
            if (!tncTemplates?.length) {
                const parentResorts = await MXTS.MxtsApi.resortParents(env, {}, [{ key: "resortId", value: resortId }]);
                const parentResortId = parentResorts.filter((parentResort) => parentResort.parentId === null).map((parentResort: MXTS.Resort) => parentResort.resortId);
                tncTemplates = await MXTS.MxtsApi.getTermAndConditionsTemplate(env, { resortid: parentResortId[0] }).catch(() => undefined);
            }
            if (tncTemplates?.length) {
                this.setState({ isTermsAndConditionsModalOpen: true, termsHtmlString: tncTemplates[0].message || "" });
            }
        }

        if (options.localized.length > 1) {
            const documentUUID =
                localContent && localContent.file && localContent.file.document
                    ? await context.mxtsApi.getDocumentUUIDPublic(env, {
                          widgetOptionsId: options._id,
                          widgetOptionsPathToFileId: "localized.file.document.fileId",
                          siteId: context.site._id,
                          localeId,
                          localeFallbackIds: getFallbackLocaleIds(context.currentLocale),
                      })
                    : "";
            if (documentUUID) {
                window.location.assign(getDownloadableUrl(documentUUID as string));
            }
        } else {
            const documentUUID =
                options.file && options.file.document ? await context.mxtsApi.getDocumentUUIDPublic(env, { widgetOptionsId: options._id, widgetOptionsPathToFileId: "file.document.fileId" }) : "";
            if (documentUUID) {
                window.location.assign(getDownloadableUrl(documentUUID as string));
            }
        }
    };

    private handleDownloadClick = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        this.downloadUrl();
    };

    private isReservationCreationFailed(): boolean {
        const { currentFlow } = this.props;
        return !!this.props.reservationState?.error && currentFlow?.type === FlowWidgetTypes.CUSTOMER;
    }

    private isLoading() {
        return this.state.isLoading && !this.isReservationCreationFailed();
    }
}

function getResetToken(): string | undefined {
    const searchParams: UpdatePasswordUrlParams = parse(window.location.search);
    return searchParams?.resetToken || (searchParams.daysTillPasswordExpires ? localStorage.getItem(LOCAL_STORAGE_KEYS.MY_ENV_TOKEN) || undefined : undefined);
}

function isLoginFailed(loginStatus: string | undefined) {
    return loginStatus === LoginStatus.FAILED || loginStatus === LoginStatus.FAILED_TOO_MANY_RETRIES;
}

function mapStateToProps(state: State): DynamicFormStoreProps {
    return {
        dynamicFilter: state.dynamicFilter,
        reservationState: state.reservationState,
        billState: state.billState,
        guestFormState: state.guestFormState,
        userLoginFormState: state.userLoginFormState,
        myEnvState: state.myEnvState,
        availabilityState: state.availabilityState,
    };
}

function mapDispatchToProps(dispatch: Dispatch<CreateReservationAction | GuestFormAction | ResultsPanelAction | MyEnvReducerAction>): DynamicFormDispatchProps {
    return { dispatchAction: dispatch };
}

function isMyEnvPreviousUrlSameAsCurrent(myEnvPreviousUrlString: string | null) {
    if (!myEnvPreviousUrlString) {
        return false;
    }
    const myEnvPreviousUrl = new URL(myEnvPreviousUrlString);
    const myEnvPreviousUrlWithoutSearch = myEnvPreviousUrl.origin + myEnvPreviousUrl.pathname;
    const currentUrlWithoutSearch = location.origin + location.pathname;
    return myEnvPreviousUrlWithoutSearch === currentUrlWithoutSearch;
}

const DynamicFormBaseType = connect<DynamicFormStoreProps, DynamicFormDispatchProps>(mapStateToProps, mapDispatchToProps)(DynamicFormBase);

export const DynamicForm = wrapProps<DynamicFormBaseProps<any>>(withGoogleReCaptcha(DynamicFormBaseType));
