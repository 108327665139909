import * as moment from "moment";

import { ApiCallOptions, BookRestrictions, MxtsApiWrapper, PagedResult } from "@maxxton/cms-mxts-api";

import { ArrayUtil } from "./array.util";
import { DATE_FORMAT } from "./constants";
import { DynamicFilter } from "../redux/reducers/dynamicFilter.types";
import { chunk } from "lodash";
import { convertMinutesToHours } from "./generic.util";

export enum RESTRICTION_RULE_OPTIONS {
    BOOK_RESTRICTION = "booking restriction",
    ROLLING_REQUEST = "rolling request",
    REQUEST = "request",
}

function getRestrictionForTotalMinutes(restrictionOnArrival: BookRestrictions[], dynamicFilter: DynamicFilter, startdate?: string) {
    const getTotalHours = restrictionOnArrival[0].maxMinutesBeforeMidnight && convertMinutesToHours(restrictionOnArrival[0].maxMinutesBeforeMidnight);
    const deadLineBookinghoursBeforeMidnight = moment(dynamicFilter.startdate || startdate, DATE_FORMAT.DEFAULT)
        .add(1, "day")
        .subtract(getTotalHours, "hours")
        .format();
    if (moment().format() > deadLineBookinghoursBeforeMidnight) {
        // If current booking timing is greater then deadline booking hours that means restrication will apply.
        return restrictionOnArrival[0].bookRestrictionOption;
    }
}

export const validateBookRestrictionConditions = (bookRestrictions: BookRestrictions[], dynamicFilter: DynamicFilter, startdate?: string) => {
    if (bookRestrictions.length) {
        const restricationsOnCurrentArrivalDate: BookRestrictions[] = [];
        bookRestrictions.forEach((restriction: BookRestrictions) => {
            const arrivalFrom = restriction.arrivalDateFrom || restriction.stayDateFrom;
            const arrivalTo = restriction.arrivalDateTo || restriction.stayDateTo;
            if (arrivalFrom && arrivalTo && (dynamicFilter.startdate || startdate)) {
                // Current arrival date selected in filter.
                const arrivalDate = new Date(moment(dynamicFilter.startdate || startdate, DATE_FORMAT.DEFAULT).format(DATE_FORMAT.MXTS));
                // Arrival date, from where the restrication is configured in content manager.
                const minArrivalDate = new Date(arrivalFrom);
                // Arrival date, till where the restrication is configured in content manager.
                const maxArrivalDate = new Date(arrivalTo);
                if (arrivalDate >= minArrivalDate && arrivalDate <= maxArrivalDate) {
                    // If arrivalDate is having restrication then push all the restrication of current arrivalDate.
                    restricationsOnCurrentArrivalDate.push(restriction);
                }
            }
        });

        // filterout bookingRestriction rule on current arrival date
        const bookingRestrictionOnArrival: BookRestrictions[] = restricationsOnCurrentArrivalDate.filter(
            (restrication) => restrication.bookRestrictionOption === RESTRICTION_RULE_OPTIONS.BOOK_RESTRICTION
        );
        // filterout rollingRequests rule on current arrival date
        const rollingRequestsOnArrival = restricationsOnCurrentArrivalDate.filter((restrication) => restrication.bookRestrictionOption === RESTRICTION_RULE_OPTIONS.ROLLING_REQUEST);
        // filterout requests rule on current arrival date
        const requestsOnArrival = restricationsOnCurrentArrivalDate.filter((restrication) => restrication.bookRestrictionOption === RESTRICTION_RULE_OPTIONS.REQUEST);

        if (bookingRestrictionOnArrival.length) {
            // bookingRestriction rule may have maxMinutesBeforeMidnight so check whether minutes restrication applied.
            return getRestrictionForTotalMinutes(bookingRestrictionOnArrival, dynamicFilter, startdate);
        } else if (rollingRequestsOnArrival.length) {
            // rollingRestriction rule may have maxMinutesBeforeMidnight so check whether minutes restrication applied.
            return getRestrictionForTotalMinutes(rollingRequestsOnArrival, dynamicFilter, startdate);
        } else if (requestsOnArrival.length) {
            // request rule will never have maxMinutesBeforeMidnight.
            return requestsOnArrival[0].bookRestrictionOption;
        }
    }
};

export const getAllBookRestrictionsByResourceIds = async (mxtsApi: MxtsApiWrapper, resourceIds: number[], env: ApiCallOptions) => {
    if (!resourceIds.length) {
        return [];
    }
    const resourceIdChunks: number[][] = chunk(resourceIds, 50);
    const allObtainedChunks: BookRestrictions[][] = await Promise.all(
        resourceIdChunks.map((ids) => mxtsApi.getAllBookRestrictions(env, { resourceIds: ids }).then((bookRestricationPage: PagedResult<BookRestrictions>) => bookRestricationPage?.content || []))
    );
    return ArrayUtil.flatten2Dimensions(allObtainedChunks);
};
