import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { ReservationState } from "../../../redux/reducers/reservationReducer";
import { getGlobalFields } from "../../../utils/globalFields";

export function redirectToPostReservationCreationPage(redirectUrl: string) {
    // POST call to redis used to get cancelled by the browser
    // and the store was not persisted in redis due to the page change
    // Adding a watcher so that the call can be completed before page change.
    // eslint-disable-next-line prefer-const
    let timeout: NodeJS.Timeout | undefined;
    const intervalTimer = window.setInterval(() => {
        if (getGlobalFields().isReservationStateStored) {
            clearInterval(intervalTimer);
            if (timeout) {
                clearTimeout(timeout);
            }
            getGlobalFields().isReservationStateStored = false;
            window.location.href = redirectUrl;
        }
    }, 100);
    timeout = setTimeout(() => clearInterval(intervalTimer), 10000);
}

export async function tryRedirectToPostReservationPage(params: { dynamicFilter: DynamicFilter; reservationState: ReservationState }, getRedirectUrl: () => Promise<string> | string) {
    const { dynamicFilter, reservationState } = params;
    const { reservation, reservedResource, error } = reservationState;
    if (
        Object.keys(reservationState).length > 0 &&
        !error &&
        dynamicFilter.reservationId &&
        reservation &&
        Object.keys(reservation).length > 0 &&
        reservedResource &&
        Object.keys(reservedResource).length > 0
    ) {
        redirectToPostReservationCreationPage(await getRedirectUrl());
    }
}
